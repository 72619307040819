import React from "react"
import { Link } from "gatsby"
import ImageMeta from "../components/ImageMeta"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ImageSlider from "../components/ImageSlider/ImageSlider"

const SmilesForTeachers = () => {
  return (
    <Layout language="en">
      <SEO
        title="Smiles For Teachers program at Braces Omaha"
        description="Braces Omaha will provide one local teacher with FREE aligner treament or braces treatment to recognize and celebrate their hard work."
      />

      <div className="sft-23">
        <div className="sft-23__container">
          <ImageMeta
            className="sft-23__logo"
            publicId="Programs/2023-sft-logo"
          />

          <ImageMeta
            className="sft-23--desktop"
            publicId="Programs/2023-sft-hero-image"
          />
          <ImageMeta
            className="sft-23--mobile"
            publicId="Programs/2023-sft-hero-image-mobile"
          />
        </div>

        <h1 className="sft-23--hidden">
          Vote Now To Help a Teacher Smile Confidently!
        </h1>

        <div className="sft-23__section">
          <div className="sft-23__container">
            <p>
              Our Smiles For Teachers program had more than 25,000 votes for our
              Top 22 teachers during our voting period. After tallying the
              votes, our 2023 Smiles For Teachers recipient is Christine Koltas.
            </p>
            <p>
              Christine, a second grade teacher at Rose Hill Elementary, will
              receive a free smile makeover with{" "}
              <Link
                to="/orthodontics-braces-invisalign/invisalign-omaha-ne/"
                title="Learn more about aligner treatment">
                aligner
              </Link>{" "}
              or{" "}
              <Link
                to="/orthodontics-braces-invisalign/braces-omaha-ne/"
                title="Learn more about braces">
                braces
              </Link>{" "}
              treatment.
            </p>
            <p>
              We hope that Christine’s smile will bring her the self-confidence
              needed as she continues to teach our community’s next generation.
            </p>

            <p>
              Follow Christine’s treatment journey on{" "}
              <a
                href="https://www.facebook.com/bracesomaha"
                title="Follow us on Facebook"
                target="_blank">
                Facebook
              </a>{" "}
              and{" "}
              <a
                href="https://www.instagram.com/braces_omaha/"
                title="Follow us on Instagram"
                target="_blank">
                Instagram
              </a>
              .
            </p>

            <p>
              Learn more about{" "}
              <Link
                to="/orthodontics-braces-invisalign/invisalign-omaha-ne/"
                title="Learn more about aligner treatment">
                aligner
              </Link>{" "}
              or{" "}
              <Link
                to="/orthodontics-braces-invisalign/braces-omaha-ne/"
                title="Learn more about braces">
                braces
              </Link>{" "}
              to see how these treatments can benefit your smile.
            </p>
          </div>
        </div>

        <div className="sft-23__section">
          <div className="sft-23__container">
            <ImageSlider
              images={[
                "BROM/DEV/2023-teachers/teachers-recipient-1",
                "BROM/DEV/2023-teachers/teachers-recipient-2"
              ]}
              useDots
              adaptiveHeight
            />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default SmilesForTeachers
